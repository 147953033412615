import { o as o_util } from "../chunks/common-namespaces-client-DXKc2YAH.mjs";
const { cookie } = o_util;
const { exists, get, remove, set } = cookie ?? {};
export {
  cookie,
  exists,
  get,
  remove,
  set
};
/*                                      */
