var _a, _b, _c;
import { o as o_util } from "../chunks/common-namespaces-client-DXKc2YAH.mjs";
import { otto } from "./nexus-client.mjs";
const dom = ((_a = o_util) == null ? void 0 : _a.dom) ?? {};
const stringToDocumentFragment = dom.stringToDocumentFragment;
const getFirstElementByTagName = dom.getFirstElementByTagName;
const getHeadElement = dom.getHeadElement;
const getBodyElement = dom.getBodyElement;
const onLoad = (_b = otto.dom) == null ? void 0 : _b.onLoad;
const onReady = (_c = otto.dom) == null ? void 0 : _c.onReady;
export {
  dom,
  getBodyElement,
  getFirstElementByTagName,
  getHeadElement,
  onLoad,
  onReady,
  stringToDocumentFragment
};
/*                                   */
